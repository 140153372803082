import React, { useState } from 'react';

const Navbar = ({ connectionStatus }) => {
  const [showManualModal, setShowManualModal] = useState(false);

  const handleManualClick = () => {
    setShowManualModal(true);
  };

  const handleCloseManualModal = () => {
    setShowManualModal(false);
  };

  const getButtonStyle = () => {
    return connectionStatus === 'connected' ? 'btn-primary' : 'btn-secondary';
  };

  return (
    <nav className={`navbar navbar-expand-lg navbar-${connectionStatus === 'connected' ? 'primary' : 'secondary'} bg-${connectionStatus === 'connected' ? 'primary' : 'secondary'}`} style={{ transition: 'background-color 1s, color 1s' }}>
      <a className="navbar-brand text-white p-2" href="https://meshbrasil.com.br">
        Controle IoT Setorizado
      </a>
      <div className="navbar-collapse justify-content-end">
        <span className={`mr-2 text-white p-2`}>
          Status: {connectionStatus === 'connected' ? 'Conectado' : 'Esperando'}
        </span>
        <button className={`btn ${getButtonStyle()}`} onClick={handleManualClick}>
          Manual de Uso
        </button>
      </div>

      {/* Manual Modal */}
      <div className={`modal ${showManualModal ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: showManualModal ? 'block' : 'none' }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className={`modal-header`}>
              <h5 className="modal-title">Manual de Uso</h5>
              <button type="button" className="close" onClick={handleCloseManualModal}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body text-justify">
              <p>1. Cadastros com o mesmo nome de usuário não são permitidos.</p>
              <p>2. A página permite um máximo de 10 tentativas de login por dispositivo.</p>
              <p>3. Atingir o número de máximo de tentativas de login inicia um timeout de 30 min.</p>
              <p>4. Certifique-se de preencher todos os campos de horários de cada set ajustável antes de enviar os horários. Aplique horários padrões (00:00) de forma ágil para On e para Off com o botão 'Aplicar a Todos'.</p>
              <p>
                <strong>Obs: Caso não cumprida a intrução 4, seu sistema de definição de horários gerará erros posteriores.</strong>
              </p>
              <p>5. A definição de Ligar/Desligar a bomba será cumprida apenas quando os horários programados Começarem/Terminarem.</p>
              <p>6. Só é possível adicionar um set ajustável (2 ajustes) por setor.</p>
              <p>7. Só é possível fazer o controle da bomba se os horários já terem sido enviados.</p>
              <p>8. Toda vez que a página for fechada ou recarregada, os valores de entrada serão reiniciados afim de diminuir a carga no servidor.</p>
              <p>
                <strong>Obs: Todos os comandos realizados, sejam eles de envio de horários ou controle da bomba, serão sempre definidos sem quaisquer falhas.</strong>
              </p>
              <p>Mantemos de forma persistente os dados salvos da última definição feita. Em casos de depuração, pode ser solicitada pelos canais de contato.</p>
              <hr />
              <p>
                <strong>Email de contato: </strong>deivisonbambui@gmail.com
              </p>
              <p>
                <strong>Telefone de contato: </strong> (37)999153816
              </p>
              </div>
            <div className={`modal-footer`}>
              <button type="button" className={`btn ${getButtonStyle()}`} onClick={handleCloseManualModal}>
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;