import React, { useState } from 'react';
import axios from 'axios';

const Registration = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');

  const validatePassword = (password) => {
    // Adicione sua lógica de validação aqui
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
    return passwordRegex.test(password);
  };

  const handleRegister = async () => {
    try {
      if (!validatePassword(password)) {
        alert('A senha deve ter no mínimo 6 caracteres, incluindo números, letras, caracteres especiais e letras maiúsculas.');
        return;
      }

      const response = await axios.post('https://meshbrasil.com.br/cadastro', {
        username,
        password,
        phone, // Incluindo o telefone na requisição
      });
  
      console.log(response.data.message);
      alert(response.data.message);
  
      // Se o cadastro for bem-sucedido, redirecione para outra página ou realize outras ações necessárias.
    } catch (error) {
      console.error('Error during registration:', error.message);
      if (error.response && error.response.status === 400) {
        // Exibir alerta personalizado para o usuário já existente
        alert('Usuário já existe. Escolha outro nome de usuário.');
      }
    }
  };

  return (
    <div className="col-md-6 mx-auto mt-5">
      <div className="card">
        <div className="card-body">
          <h4 className="card-title text-center">Cadastro</h4>
          <hr /><br />
          <div className="form-group">
            <label>Nome de usuário:</label>
            <input
              type="text"
              className="form-control"
              placeholder="Insira seu username aqui"
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <br />
          <div className="form-group">
            <label className="font-weight-bold">Senha:</label>
            <input
              type="password"
              className="form-control"
              placeholder="Insira sua senha aqui"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <br />
          <div className="form-group">
            <label>Telefone:</label>
            <input
              type="text"
              className="form-control"
              placeholder="Insira seu telefone aqui"
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <br />
          <button className="btn btn-light form-control" onClick={handleRegister}>Cadastrar</button>
        </div>
      </div>
    </div>
  );
};

export default Registration;