import React, { useState } from 'react';
import mqtt from 'mqtt';
import Navbar from './components/Navbar';
import Registration from './components/Registration';
import Login from './components/Login';
import PageApp from './components/PageApp';
import Footer from './components/Footer';

const App = () => {
  const [clientId, setClientId] = useState('');
  const [mqttConnectionStatus, setMqttConnectionStatus] = useState('');
  const [mqttClient, setMqttClient] = useState(null);

  const handleLogin = (newClientId) => {
    setClientId(newClientId);
    connectToMQTT(newClientId);
  };

  const connectToMQTT = (clientId) => {
    const client = mqtt.connect('wss://eef86b6f.ala.us-east-1.emqxsl.com:8084/mqtt', {
      username: 'doc',
      password: '34597320',
      clientId,
    });

    client.on('connect', () => {
      console.log('Connected to MQTT broker');
      setMqttConnectionStatus('connected');
      setMqttClient(client);
    });

    client.on('error', (err) => {
      console.error('MQTT error:', err);
      setMqttConnectionStatus('error');
    });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Navbar connectionStatus={mqttConnectionStatus} />
      <div style={{ flex: 1 }}>
        {clientId ? (
          <PageApp mqttConnectionStatus={mqttConnectionStatus} mqttClient={mqttClient} />
        ) : (
          <>
            <Registration />
            <Login onLogin={handleLogin} />
          </>
        )}
      </div>
      <Footer connectionStatus={mqttConnectionStatus} />
    </div>
  );
};

export default App;