import React from 'react';

const Footer = ({ connectionStatus }) => {
  const getFooterStyle = () => {
    return connectionStatus === 'connected'
      ? 'footer-connected'
      : connectionStatus === 'error'
      ? 'footer-error'
      : 'footer-default';
  };

  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <footer className={`footer bg-${connectionStatus === 'connected' ? 'primary' : 'secondary'} ${getFooterStyle()}`} style={{ transition: 'background-color 1s, color 1s' }}>
      <div className="container">
        <p className="text-white text-center" style={{ margin: '10px 0' }}>
          © {getCurrentYear()} Agrisoft Soluções. Todos os direitos reservados.
        </p>
      </div>
    </footer>
  );
};

export default Footer;