
import React, { useState, useEffect, useCallback } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import greenImage from './icons/green.png';
import redImage from './icons/red.png';
import { CSVLink } from 'react-csv';

function PageApp({ mqttConnectionStatus, mqttClient }) {
  const [setorSelecionado, setSetorSelecionado] = useState('Setor 1');
  const [ajustes, setAjustes] = useState([]);
  const [bombaStatus, setBombaStatus] = useState({});
  const [autoFillOn, setAutoFillOn] = useState('00:00');
  const [autoFillOff, setAutoFillOff] = useState('00:00');
  const [setoresComHorarios, setSetoresComHorarios] = useState([]);
  const [bombaConfiguracoes, setBombaConfiguracoes] = useState({});
  const [payload, setPayload] = useState('');
  const [lastPayload, setLastPayload] = useState('');
  const [estadoBomba, setEstadoBomba] = useState('');
  const [showDownloadLink, setShowDownloadLink] = useState(false);
  const [csvData, setCsvData] = useState({});

  const convertToCSV = useCallback(() => {
    if (payload.trim() !== '') {
      const data = JSON.parse(payload);
      const numSetor = parseInt(data.SE.match(/\d+/)[0], 10);
  
      const headers = [
        'Hora de Ligação', 'Minuto de Ligação', 'Dia da Semana de Ligação',
        'Hora de Desligação', 'Minuto de Desligação', 'Dia da Semana de Desligação',
        'Status da Bomba', 'Modo'
      ];
  
      const csvData = [
        headers,
        ...data.T.slice(0, -2).reduce((result, value, index, array) => {
          if (index % 6 === 0) {
            result.push(array.slice(index, index + 6));
          }
          return result;
        }, []).map(item => {
          const [ligar_hora, ligar_minuto, ligar_dia_semana, desligar_hora, desligar_minuto, desligar_dia_semana] = item;
          return [
            ligar_hora,
            ligar_minuto,
            ligar_dia_semana,
            desligar_hora,
            desligar_minuto,
            desligar_dia_semana,
            data.T[data.T.length - 2],
            data.T[data.T.length - 1],
          ];
        }),
      ];
  
      setCsvData(prevCsvData => ({
        ...prevCsvData,
        [`data_Setor${numSetor}.csv`]: csvData,
      }));
  
      setShowDownloadLink(true);
    }
  }, [payload]);  
  
  const diasDaSemana = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];

  const adicionarAjuste = () => {
    if (mqttConnectionStatus === 'error') {
      alert('Sem Conexão');
      return;
    }
    const ajustesDoSetor = ajustes.filter(ajuste => ajuste.setor === setorSelecionado);
    if (ajustesDoSetor.length >= 1) {
      alert('Cada setor pode ter no máximo 2 ajustes.');
      return;
    }
  
    const novoAjuste = {
      setor: setorSelecionado,
      ajustes: [
        { ligar: {}, desligar: {} },
        { ligar: {}, desligar: {} }
      ],
      modoManual: 0, // 0 para Manual, 1 para Automático
    };
  
    setAjustes([...ajustes, novoAjuste]);
  };

  const AjusteButton = ({ connectionStatus, isManual, setorIndex }) => {
    const buttonClass = `btn btn-sm ${isManual ? 'btn-outline-' : 'btn-'}${connectionStatus === 'connected' ? 'primary' : 'secondary'}`;
  
    const toggleModoManual = () => {
      if (mqttConnectionStatus === 'error') {
        alert('Sem Conexão');
        return;
      }
  
      const novosAjustes = [...ajustes];
      novosAjustes[setorIndex].modoManual = novosAjustes[setorIndex].modoManual === 0 ? 1 : 0;
  
      setAjustes(novosAjustes);
    };
    return (
      <button className={buttonClass} onClick={toggleModoManual}>
        {isManual ? 'Manual' : 'Automático'}
      </button>
    );
  };
  
  const CapturarHorariosButton = ({ setorIndex, indexRelativo }) => {
    const handleCapturarHorarios = () => {
      if (mqttConnectionStatus === 'error') {
        alert('Sem Conexão');
        return;
      }
  
      const numSetor = setorIndex + 1;
      let horariosCapturados = [];
      let modoManualSet = 0;
  
      // Encontrar o ajuste correspondente ao setorIndex
      const ajuste = ajustes[indexRelativo];
  
      if (ajuste) {
        modoManualSet = ajuste.modoManual;
  
        // Iterar sobre os ajustes do setor
        ajuste.ajustes.forEach((ajusteItem) => {
          diasDaSemana.forEach((dia, diaIndex) => {
            // Verificar se ajusteItem.ligar[dia] está definido antes de fazer o split
            if (ajusteItem.ligar && (ajusteItem.ligar[dia] || ajusteItem.ligar[dia] === '00:00')) {
              const ligarTime = ajusteItem.ligar[dia].split(':');
              const desligarTime = ajusteItem.desligar[dia].split(':');
  
              // Extrair hora e minuto dos horários de ligar e desligar
              const ligarHour = parseInt(ligarTime[0], 10);
              const ligarMinute = parseInt(ligarTime[1], 10);
              const desligarHour = parseInt(desligarTime[0], 10);
              const desligarMinute = parseInt(desligarTime[1], 10);
  
              // Adicionar informações ao array horariosCapturados
              horariosCapturados.push(
                ligarHour, ligarMinute, diaIndex , // Hora e dia de ligar
                desligarHour, desligarMinute, diaIndex, // Hora e dia de desligar
              );
            }
          });
        });
  
        // Adicionar o valor do modoManual ao final do array
        const setorIndexBomba = 'Setor ' + numSetor;
        const bombaStatusDefine = bombaStatus[setorIndexBomba] ? 1 : 0;
        
        horariosCapturados.push(bombaStatusDefine, modoManualSet);
      }
  
      setSetoresComHorarios(prevSetores => {
        const setoresAtualizados = new Set([...prevSetores, ajuste.setor]);
        return Array.from(setoresAtualizados);
      });

      // console.log('SE: ', numSetor);
      // console.log('T:', horariosCapturados);
      setPayload(`{"SE": "S${numSetor}", "T": [${horariosCapturados}]}`);

      alert('Horários Enviados com sucesso');
    };
  
    return (
      <button
        className={`btn btn-sm btn-outline-${mqttConnectionStatus === 'connected' ? 'primary' : 'secondary'}`}
        onClick={handleCapturarHorarios}
      >
        Enviar Horários
      </button>
    );
  };  

  const handleAjusteChange = (setorIndex, ajusteIndex, tipo, dia, valor) => {
    if (mqttConnectionStatus === 'error') {
      alert('Sem Conexão');
      return;
    }
    const novosAjustes = [...ajustes];
    novosAjustes[setorIndex].ajustes[ajusteIndex][tipo][dia] = valor;
    setAjustes(novosAjustes);
  };

  const handleAutoFill = (setorIndex, ajusteIndex) => {
    if (mqttConnectionStatus === 'error') {
      alert('Sem Conexão');
      return;
    }
    const novosAjustes = [...ajustes];
    const horarioPadraoOn = autoFillOn; 
    const horarioPadraoOff = autoFillOff; 
  
    diasDaSemana.forEach(dia => {
      novosAjustes[setorIndex].ajustes[ajusteIndex]['ligar'][dia] = horarioPadraoOn;
      novosAjustes[setorIndex].ajustes[ajusteIndex]['desligar'][dia] = horarioPadraoOff;
    });
  
    setAjustes(novosAjustes);
  };  

  const toggleBomba = (setor, indexRelativo) => {
    if (mqttConnectionStatus === 'error') {
      alert('Sem Conexão');
      return;
    }
  
    const numSetorBomba = parseInt(setor.match(/\d+/)[0], 10);
  
    // Verificar se o setorIndex é válido
    if (indexRelativo < 0 || indexRelativo >= ajustes.length) {
      alert('Setor inválido');
      return;
    }
  
    if (!setoresComHorarios.includes(setor)) {
      alert('Envie pelo menos um horário para o setor antes de ligar ou desativar a bomba.');
      return;
    }
  
    // Atualizar o estado da bomba
    setBombaStatus(prevStatus => ({
      ...prevStatus,
      [setor]: !prevStatus[setor]
    }));
  
    // Atualizar as configurações da bomba
    setBombaConfiguracoes(prevConfiguracoes => {
      const novasConfiguracoes = { ...prevConfiguracoes };
      novasConfiguracoes[setor] = {
        ligada: !prevConfiguracoes[setor]?.ligada,
        codigosErro: [],
      };
      return novasConfiguracoes;
    });
  
    setSetoresComHorarios(prevSetores => {
      const setoresAtualizados = new Set([...prevSetores, ajustes[indexRelativo].setor]);
      return Array.from(setoresAtualizados);
    });
  
    const horariosCapturados = [];
  
    // Capturar os horários apenas para o setor selecionado
    const ajuste = ajustes[indexRelativo];
    if (ajuste) {
      ajuste.ajustes.forEach((ajusteItem) => {
        diasDaSemana.forEach((dia, diaIndex) => {
          if (ajusteItem.ligar && (ajusteItem.ligar[dia] || ajusteItem.ligar[dia] === '00:00')) {
            const ligarTime = ajusteItem.ligar[dia].split(':');
            const desligarTime = ajusteItem.desligar[dia].split(':');
  
            const ligarHour = parseInt(ligarTime[0], 10);
            const ligarMinute = parseInt(ligarTime[1], 10);
            const desligarHour = parseInt(desligarTime[0], 10);
            const desligarMinute = parseInt(desligarTime[1], 10);
  
            horariosCapturados.push(
              ligarHour, ligarMinute, diaIndex + 1,
              desligarHour, desligarMinute, diaIndex + 1,
            );
          }
        });
      });
  
      // Adicionar o valor do modoManual e o status da bomba ao final
      horariosCapturados.push(bombaStatus[setor] ? 0 : 1, ajuste.modoManual);
    }
  
    // console.log('SE: ', numSetorBomba);
    // console.log('T:', horariosCapturados);
    setPayload(`{"SE": "S${numSetorBomba}", "T": [${horariosCapturados}]}`);
    alert('Bomba Definida com sucesso');
  };   

  const renderEstadoBombaInfo = () => {
    // Check if estadoBomba is not empty
    if (!estadoBomba || estadoBomba.trim() === '') {
      return <p></p>;
    }
  
    // Parse estadoBomba as JSON
    let estadoBombaData;
    try {
      estadoBombaData = JSON.parse(estadoBomba);
    } catch (error) {
      console.error('Error parsing estadoBomba as JSON:', error);
      return <p>Error parsing data for Estado Geral da Bomba</p>;
    }
  
    // Check if any of the first 15 numbers in the array is equal to 1
    const activeSectors = estadoBombaData.R
      .slice(0, 15)
      .map((value, index) => (value === 1 ? index + 1 : null))
      .filter((position) => position !== null);
  
    // Determine the pump status based on the last value in the array (16th)
    let pumpStatus = '';
    switch (estadoBombaData.R[15]) {
      case 0:
        pumpStatus = 'Desligada';
        break;
      case 1:
        pumpStatus = 'Ligada';
        break;
      case 2:
        pumpStatus = 'Falha';
        break;
      default:
        pumpStatus = 'Desconhecido';
    }
  
    return (
      <table style={tableResponsiveStyle}>
        <thead>
          <tr style={tableCellStyle}>
            <th style={tableCellStyle}>Setores Ativos</th>
            <th style={tableCellStyle}>Bomba</th>
          </tr>
        </thead>
        <tbody style={tableCellStyle}>
          <tr style={tableCellStyle}>
            <td style={tableCellStyle}>{activeSectors.length > 0 ? activeSectors.join(', ') : 'Nenhum setor ativo'}</td>
            <td style={tableCellStyle}>{pumpStatus}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  const tableResponsiveStyle = {
    borderCollapse: 'collapse',
    width: '100%',
  };

  const tableCellStyle = {
    border: '1px solid #ddd',
    padding: '8px',
    textAlign: 'center',
  };

  useEffect(() => {
    if (mqttClient && mqttConnectionStatus === 'connected') {
      const handleIncomingMessage = (topic, message) => {
        // Aqui você deve verificar se a mensagem pertence ao tópico desejado
        if (topic === `${mqttClient.options.clientId}/irrigacaobomba`) {
          const payload = message.toString();
          // Faça o processamento da mensagem conforme necessário
          // Atualize o estado do componente com a nova mensagem
          setEstadoBomba(payload);
        }
      };
      
      // Garanta que a inscrição no tópico seja feita corretamente
      const topic1 = `${mqttClient.options.clientId}/irrigacaobomba`;
      mqttClient.subscribe(topic1, { qos: 1 }, (error) => {
        if (error) {
          console.log('Subscribe to topics error', error);
          return;
        }
        console.log(`Subscribe to topics: ${topic1}`);
      });
      
      // Adicione o ouvinte de mensagens aqui
      mqttClient.on('message', handleIncomingMessage);  
    }
  }, [mqttConnectionStatus, mqttClient]);  

  useEffect(() => {
    setBombaConfiguracoes((prevConfiguracoes) => {
      const novasConfiguracoes = { ...prevConfiguracoes };
  
      ajustes.forEach((index) => {
        const setor = `Setor ${index + 1}`;
        if (!novasConfiguracoes[setor]) {
          novasConfiguracoes[setor] = {
            ligada: false,
            codigosErro: [],
          };
        }
      });
  
      return novasConfiguracoes;
    });
  
    if (mqttClient && mqttConnectionStatus === 'connected' && payload.trim() !== '') {
      let data = JSON.parse(payload);
      let seValue = data.SE.match(/\d+/);

      const topic = `${mqttClient.options.clientId}/irrigacao/setor${seValue}`;
      const message = payload;
  
      if (message !== lastPayload) {
        
        //publicação no tópico MQTT
        mqttClient.publish(topic, message, { qos: 0, retain: true }, (err) => {
          if (err) {
            console.error('Erro ao publicar no MQTT:', err);
          } else {
            console.log('Message published successfully!');
            console.log('Topic: ', topic);
            console.log('Message: ', message);
            setLastPayload(message);
          }
        });
        fetch('https://meshbrasil.com.br/storePayload', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            clientId: mqttClient.options.clientId,
            payload: message,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            console.log(data.message);
            setLastPayload(message);
          })
          .catch((error) => {
            console.error('Error sending payload to the server:', error);
          });
      }  
    }
    
  }, [ajustes, payload, mqttConnectionStatus, mqttClient, lastPayload]);  

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const message = 'Os dados inseridos serão perdidos. Tem certeza que deseja sair?';
      event.returnValue = message; // Padrão para navegadores mais antigos
      return message;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    convertToCSV();
  }, [payload, convertToCSV]);  

    // Adicione estas linhas para carregar csvData do localStorage
  useEffect(() => {
    const storedCsvData = localStorage.getItem('csvData');
    if (storedCsvData) {
      setCsvData(JSON.parse(storedCsvData));
      setShowDownloadLink(true);
    }
  }, []);

  // Adicione estas linhas para salvar csvData no localStorage sempre que for atualizado
  useEffect(() => {
    if (showDownloadLink) {
      localStorage.setItem('csvData', JSON.stringify(csvData));
    }
  }, [csvData, showDownloadLink]);

  return (
    <div>
      <div className="container mt-4">

        {/* Card Ajustes de Horários */}
        <div className="card mb-4">
          <div className="card-header text-center"> 
            <h5>Ajustes de Horários</h5>
          </div>
          <div className="card-body">
            <div className="form-group">
              <label htmlFor="setorSelect">Selecione o Setor:</label>
              <hr />
              <select
                id="setorSelect"
                className="form-control"
                value={setorSelecionado}
                onChange={e => setSetorSelecionado(e.target.value)}
              >
                {[...Array(15).keys()].map(num => (
                  <option key={num} value={`Setor ${num+1}`}>{`Setor ${num+1}`}</option>
                ))}
              </select>
              <hr />
            </div>
            <button className={`form-control btn btn-${mqttConnectionStatus === 'connected' ? 'primary' : 'secondary'}`} onClick={adicionarAjuste}>Adicionar Ajuste</button>
          </div>
        </div>

        {/* Card Ajustes Programados */}
        <div className="card mb-4">
          <div className="card-header text-center">
            <h5>Definição dos Ajustes</h5>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table style={tableResponsiveStyle}>
                <thead>
                  <tr style={tableCellStyle}>
                    <th style={tableCellStyle}>Setor</th>
                    <th style={tableCellStyle}>Ajuste</th>
                    {diasDaSemana.map((dia, diaIndex) => (
                      <th key={diaIndex} style={tableCellStyle}>{dia}</th>
                    ))}
                    <th style={tableCellStyle}>Auto Preencher</th>
                  </tr>
                </thead>
                <tbody>
                  {ajustes.map((ajuste, index) => (
                    ajuste.ajustes.map((ajusteItem, subIndex) => (
                      <tr key={subIndex}>
                        {subIndex === 0 ? (
                          <td rowSpan={ajuste.ajustes.length} style={tableCellStyle}>
                            {ajuste.setor}
                            <br /><br />
                            <AjusteButton
                              connectionStatus={mqttConnectionStatus}
                              isManual={ajuste.modoManual === 0}
                              setorIndex={index}
                            />
                            <br /><br />
                            <CapturarHorariosButton 
                              setorIndex={parseInt(ajuste.setor.match(/\d+/)[0], 10) - 1} 
                              indexRelativo={index}
                            />
                          </td>
                        ) : null}
                        <td style={tableCellStyle}>
                          Ajuste {subIndex + 1}
                        </td>
                        {diasDaSemana.map((dia, diaIndex) => (
                          <td key={diaIndex} style={tableCellStyle}>
                            <div className="form-group">
                              <div>
                                <label>On:</label>
                                <input
                                  type="time"
                                  value={ajusteItem.ligar[dia] || ''}
                                  onChange={(e) => handleAjusteChange(index, subIndex, 'ligar', dia, e.target.value)}
                                />
                              </div>
                              <br />
                              <div>
                                <label>Off:</label>
                                <input
                                  type="time"
                                  value={ajusteItem.desligar[dia] || ''} 
                                  onChange={(e) => handleAjusteChange(index, subIndex, 'desligar', dia, e.target.value)}
                                />
                              </div>
                            </div>
                          </td>
                        ))}
                        <td style={tableCellStyle}>
                          <div className="form-group">
                            <label>On:</label>
                            <input
                              type="time"
                              value={autoFillOn}
                              onChange={(e) => setAutoFillOn(e.target.value)}
                            />
                            <br /><br />
                            <label>Off:</label>
                            <input
                              type="time"
                              value={autoFillOff}
                              onChange={(e) => setAutoFillOff(e.target.value)}
                            />
                          </div>
                          <br />
                          <button
                            className={`btn btn-sm btn-outline-${mqttConnectionStatus === 'connected' ? 'primary' : 'secondary'}`}
                            onClick={() => handleAutoFill(index, subIndex)}
                          >
                            Aplicar em Todos
                          </button>
                        </td>
                      </tr>
                    ))
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* Card Controle da Bomba */}
        <div className="card">
          <div className="card-header text-center">
            <h5>Controle da Bomba</h5>
          </div>
          <div className="card-body">
            <table style={tableResponsiveStyle}>
              <thead>
                <tr style={tableCellStyle}>
                  <th style={tableCellStyle}>Setor</th>
                  <th style={tableCellStyle}>Comando</th>
                  <th style={tableCellStyle}>Ação</th>
                </tr>
              </thead>
              <tbody style={tableCellStyle}>
                  {ajustes.map((ajuste, index) => (
                    <tr key={index + 1} style={tableCellStyle}>
                      <td style={tableCellStyle}>{ajuste.setor}</td>
                      <td className={bombaConfiguracoes[ajuste.setor]?.ligada ? 'text-success' : 'text-danger'} style={tableCellStyle}>
                        <img
                          src={bombaConfiguracoes[ajuste.setor]?.ligada ? greenImage : redImage}
                          alt={bombaConfiguracoes[ajuste.setor]?.ligada ? 'Ativo' : 'Inativo'}
                        />
                      </td>
                      <td style={tableCellStyle}>
                        <button
                          className={`btn ${bombaConfiguracoes[ajuste.setor]?.ligada ? 'btn-outline-danger' : 'btn-outline-success'}`}
                          onClick={() => toggleBomba(ajuste.setor, index)}
                        >
                          {bombaConfiguracoes[ajuste.setor]?.ligada ? 'Desligar' : 'Ligar'}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
            </table>
          </div>
        </div>

        <br />
        {/* Card Estado da Bomba */}
        <div className="card">
          <div className="card-header text-center">
            <h5>Estado Geral da Bomba</h5>
          </div>
          <div className="card-body">
            {renderEstadoBombaInfo()}
          </div>
        </div>

        <br />
        {/* Card Downloads */}
        <div className="card">
          <div className="card-header text-center">
            <h5>Downloads</h5>
          </div>
          {showDownloadLink && (
            <div className="card-body">
              <table style={tableResponsiveStyle}>
                <thead>
                  <tr style={tableCellStyle}>
                    <th style={tableCellStyle}>Índice</th>
                    <th style={tableCellStyle}>Links</th>
                  </tr>
                </thead>
                <tbody style={tableCellStyle}>
                  {Object.keys(csvData).map((fileName, index) => (
                    <tr key={index} style={tableCellStyle}>
                      <td style={tableCellStyle}>{index + 1}</td>
                      <td style={tableCellStyle}>
                        <CSVLink data={csvData[fileName]} filename={fileName} target="_blank">
                          {fileName}
                        </CSVLink>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div> 
        <br />
      </div>
    </div>
  );
}

export default PageApp;