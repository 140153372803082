import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginAttempts, setLoginAttempts] = useState(0);
  const [isLoginDisabled, setIsLoginDisabled] = useState(false);
  const [timerId, setTimerId] = useState(null);

  const handleLogin = async () => {
    try {
      const response = await axios.post('https://meshbrasil.com.br/login', {
        username,
        password,
      });

      console.log(response.data.message);
      onLogin(response.data.clientId);
    } catch (error) {
      if (error.message === 'Request failed with status code 403') {
        alert('Aguardando liberação pelo administrador!');
      } else {
        alert('Usuário ou Senha Incorretos!');
        console.error('Error during login:', error.message);
        // Increment login attempts
        setLoginAttempts(loginAttempts + 1);
        // Check if the maximum login attempts are reached
        if (loginAttempts >= 9) {
          alert('Máximo de tentativas de login atingido. Por favor, tente novamente mais tarde (> 30 min).');
          // Disable the login button
          setIsLoginDisabled(true);

          // Start a timer for 30 minutes (in milliseconds)
          const id = setTimeout(() => {
            // Enable the login button after the timer expires
            setIsLoginDisabled(false);
            // Reset the login attempts
            setLoginAttempts(0);
          }, 30 * 60 * 1000); // 30 minutes

          // Save the timerId to clear it later
          setTimerId(id);
        }
      }
    }
  };

  useEffect(() => {
    // Cleanup timer and reset login attempts on component unmount
    return () => {
      clearTimeout(timerId);
      setLoginAttempts(0);
    };
  }, [timerId]);

  return (
    <div className="col-md-6 mx-auto mt-5">
      <div className="card">
        <div className="card-body">
          <h4 className="card-title text-center">Login</h4>
          <hr /><br />
          <div className="form-group">
            <label>Nome de usuário:</label>
            <input
              type="text"
              className="form-control"
              placeholder="Insira seu username aqui"
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <br />
          <div className="form-group">
            <label>Senha:</label>
            <input
              type="password"
              className="form-control"
              placeholder="Insira sua senha aqui"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <br />
          <button
            className="btn btn-light form-control"
            onClick={handleLogin}
            disabled={isLoginDisabled}
          >
            Logar
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;